<style scoped lang="less">
  .evaluation-content {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .tabs {
    display: inline-block;
    border: 1px solid #dedede;
    border-bottom: none;
    margin-top: 18px;
    span {
      transition: all .3s;
      color: #657180;
      font-size: 15px;
      display: inline-block;
      line-height: 35px;
      height: 35px;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;
      & + span {
        border-left: 1px solid #dedede;
      }
      &:hover, &.active {
        color: #657180;
      }
      &.active {
        background-color: #EEE;
      }
    }
  }
  .details {
    overflow: auto;
    flex: 1;
    height: 0;
  }
  .content-text {
    width: 100%;
    padding: 10px;
    display: block;
    border: 1px solid #dedede;
  }
  .action {
    padding: 10px 0;
    display: flex;
    justify-content: space-evenly;
  }
</style>

<style lang="less">
  .evaluation-content {
    .uheight {
      textarea {
        max-height: unset;
        min-height: unset;
        height: 100%;
      }
    }
  }
</style>

<template>
  <div class="evaluation-content">
    <div class="details scrollbar">
      <fm-form :inline="3" label-width="120px">
        <fm-form-item label="姓名">
          <fm-input-new readonly v-model="formData.workerName" placeholder="请输入姓名" />
        </fm-form-item>
        <fm-form-item label="性别">
          <fm-input-new :readonly="!$authFunProxy['evaluation.index-my'] || type !== 'draw'" v-model="formData.sex" placeholder="请输入性别" />
        </fm-form-item>
        <fm-form-item label="出生年月">
          <fm-input-new :readonly="!$authFunProxy['evaluation.index-my'] || type !== 'draw'" v-model="formData.birth" placeholder="请输入出生年月" />
        </fm-form-item>
        <fm-form-item label="民族">
          <fm-input-new :readonly="!$authFunProxy['evaluation.index-my'] || type !== 'draw'" v-model="formData.clan" placeholder="请输入民族" />
        </fm-form-item>
        <fm-form-item label="政治面貌">
          <fm-input-new :readonly="!$authFunProxy['evaluation.index-my'] || type !== 'draw'" v-model="formData.politicalStatus" placeholder="请输入政治面貌" />
        </fm-form-item>
        <fm-form-item label="文化程度">
          <fm-input-new :readonly="!$authFunProxy['evaluation.index-my'] || type !== 'draw'" v-model="formData.education" placeholder="请输入文化程度" />
        </fm-form-item>
        <fm-form-item label="管理岗职务">
          <fm-input-new :readonly="!$authFunProxy['evaluation.index-my'] || type !== 'draw'" v-model="formData.glgzw" placeholder="请输入管理岗职务" />
        </fm-form-item>
        <fm-form-item label="专技岗职务">
          <fm-input-new :readonly="!$authFunProxy['evaluation.index-my'] || type !== 'draw'" v-model="formData.zjgzw" placeholder="请输入专技岗职务" />
        </fm-form-item>
        <fm-form-item label="工勤岗位等级">
          <fm-input-new :readonly="!$authFunProxy['evaluation.index-my'] || type !== 'draw'" v-model="formData.gqgwdj" placeholder="请输入工勤岗位等级" />
        </fm-form-item>
        <fm-form-item label="本人总结" style="width: 100%;">
          <fm-input-new class="uheight" v-if="type === 'draw' || formData.status === 'submit'" :readonly="!$authFunProxy['evaluation.index-my'] || type !== 'draw'" v-model="formData.content" type="textarea" style="flex: 1; height: 50vh;"/>
          <fm-input-new class="uheight" v-else readonly type="textarea" style="flex: 1; height: 50vh;"/>
        </fm-form-item>
      </fm-form>

      <fm-form v-if="type !== 'draw'" label-width="120px" style="margin-top: 18px;" label-align="center">
        <fm-form-item label="主管领导评语及考核等次建议">
          <fm-input-new v-if="checkData.lead.status === 'submit'" readonly :value="content.lead" type="textarea" style="flex: 1;"/>
          <fm-input-new v-else readonly type="textarea" style="flex: 1;"/>
        </fm-form-item>
        <fm-form-item label="考核单位审核意见">
          <fm-input-new v-if="checkData.org.status === 'submit'" readonly :value="content.org" type="textarea" style="flex: 1;"/>
          <fm-input-new v-else readonly type="textarea" style="flex: 1;"/>
        </fm-form-item>
        <fm-form-item label="个人意见">
          <fm-input-new v-if="checkData.my.status === 'submit'" readonly :value="content.my" type="textarea" style="flex: 1;"/>
          <fm-input-new v-else readonly type="textarea" style="flex: 1;"/>
        </fm-form-item>
        <fm-form-item label="未确定等次或不参加考核情况说明">
          <fm-input-new v-if="checkData.other.status === 'submit'" readonly :value="content.other" type="textarea" style="flex: 1;"/>
          <fm-input-new v-else readonly type="textarea" style="flex: 1;"/>
        </fm-form-item>
      </fm-form>

      <template v-if="type !== 'draw'">
        <div v-for="tab in tabs" :key="tab.key" v-loadingx="loading.tab[tab.key]">
          <div style="display: flex;justify-content: space-between; align-items: center;">
            <div class="tabs">
              <span>{{tab.label}}</span>
            </div>
            <div>
              <fm-btn @click="checkSubmit(tab.key, 'draw')">{{checkData[tab.key].status === 'submit' ? '撤回' : '保存'}}</fm-btn>
              <fm-btn v-if="checkData[tab.key].status !== 'submit'" @click="checkSubmit(tab.key, 'submit')">提交</fm-btn>
            </div>
          </div>
          <textarea rows="5" class="content-text" v-model="checkData[tab.key].content"></textarea>
        </div>
      </template>
    </div>
    <div class="action" v-if="data && type === 'draw' && $authFunProxy['evaluation.index-my']">
      <fm-btn long v-loadingx="loading.draw" @click="detailsSubmit('draw')">
        <template v-if="data.status === 'submit'">
          {{loading.draw ? '撤回中...' : '撤回'}}
        </template>
        <template v-else>
          {{loading.draw ? '保存中...' : '保存'}}
        </template>
      </fm-btn>
      <fm-btn v-if="data.status !== 'submit'" long v-loadingx="loading.submit" @click="detailsSubmit('submit')">{{loading.submit ? '提交中...' : '提交'}}</fm-btn>
    </div>
  </div>
</template>

<script>
import { parseData } from '../lib'
import { appraisalYearDetailsRequest } from '@/api'

export default {
  props: {
    data: { type: Object, default: () => ({}) },
    type: String
  },
  data () {
    const { formData, leadCheckData, orgCheckData, myCheckData, otherCheckData } = parseData(this.data)
    return {
      formData,
      checkData: {
        lead: leadCheckData,
        org: orgCheckData,
        my: myCheckData,
        other: otherCheckData
      },
      content: {
        lead: leadCheckData.content,
        org: orgCheckData.content,
        my: myCheckData.content,
        other: otherCheckData.content
      },
      loading: {
        draw: false,
        submit: false,
        tab: {
          lead: false,
          org: false,
          my: false,
          other: false
        }
      }
    }
  },
  watch: {
    data () {
      const { formData, leadCheckData, orgCheckData, myCheckData, otherCheckData } = parseData(this.data)
      this.formData = formData
      this.checkData.lead = leadCheckData
      this.checkData.org = orgCheckData
      this.checkData.my = myCheckData
      this.checkData.other = otherCheckData
      this.content.lead = leadCheckData.content
      this.content.org = orgCheckData.content
      this.content.my = myCheckData.content
      this.content.other = otherCheckData.content
    }
  },
  computed: {
    tabs () {
      const funs = this.$authFunProxy
      const type = this.type
      const orgWorkerIds = (this.formData.orgWorkerIds || '').split(',').filter(v => v).map(v => Number(v))
      const leadWorkerIds = (this.formData.leadWorkerIds || '').split(',').filter(v => v).map(v => Number(v))
      const currentWorkerId = (this.$store.getters.userPrevilege.find(v => v.objType === 'worker') || { objId: null }).objId
      return type === 'opinion' ? [
        { label: '个人意见', key: 'my' }
      ] : [
        {
          label: '考核单位审核意见', key: 'org', auth: orgWorkerIds.includes(currentWorkerId)
        },
        {
          label: '主管领导审核意见', key: 'lead', auth: leadWorkerIds.includes(currentWorkerId)
        },
        {
          label: '未确定等次或不参加考核情况说明', key: 'other', auth: orgWorkerIds.includes(currentWorkerId)
        }
      ].filter(v => {
        return funs['evaluation.index-' + v.key + 'check'] && v.auth !== false
      })
    }
  },
  methods: {
    async checkSubmit (type, status) {
      this.loading.tab[status] = true
      try {
        await appraisalYearDetailsRequest.check([{
          type: type,
          status: status,
          appraisalYearDetailId: this.data.id,
          content: this.checkData[type].content
        }])
        this.$notice.success('操作成功')
        this.$emit('reload', this.data)
      } catch (error) {
        this.$notice.success(error.message)
      }
      this.loading.tab[status] = false
    },
    async detailsSubmit (status) {
      this.loading[status] = true
      try {
        if (status === 'submit') {
          await appraisalYearDetailsRequest.status(this.data.id, { status: 'submit' })
          this.$notice.success('已提交')
        } else if (this.data.status === 'submit') {
          await appraisalYearDetailsRequest.status(this.data.id, { status: 'draw' })
          this.$notice.success('撤回成功')
        } else {
          await appraisalYearDetailsRequest.update(this.data.id, this.formData)
          this.$notice.success('已保存')
        }
        this.$emit('reload', this.data)
      } catch (error) {
        console.error(error)
        this.$notice.error(error.message)
      }
      this.loading[status] = false
    }
  },
}
</script>
